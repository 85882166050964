import _definePage_default_0 from '/var/www/continental/src/pages/app/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/var/www/continental/src/pages/app/feedback/auth/role.vue?definePage&vue'
import _definePage_default_3 from '/var/www/continental/src/pages/app/feedback/capa-management.vue?definePage&vue'
import _definePage_default_4 from '/var/www/continental/src/pages/app/feedback/customer-communication.vue?definePage&vue'
import _definePage_default_5 from '/var/www/continental/src/pages/app/feedback/dashboard/index.vue?definePage&vue'
import _definePage_default_6 from '/var/www/continental/src/pages/app/feedback/dashboard/complaint.vue?definePage&vue'
import _definePage_default_7 from '/var/www/continental/src/pages/app/feedback/dashboard/department/index.vue?definePage&vue'
import _definePage_default_8 from '/var/www/continental/src/pages/app/feedback/dashboard/feedback.vue?definePage&vue'
import _definePage_default_9 from '/var/www/continental/src/pages/app/feedback/dashboard/rca.vue?definePage&vue'
import _definePage_default_10 from '/var/www/continental/src/pages/app/feedback/dashboard/ticket.vue?definePage&vue'
import _definePage_default_11 from '/var/www/continental/src/pages/app/feedback/entry/index.vue?definePage&vue'
import _definePage_default_12 from '/var/www/continental/src/pages/app/feedback/entry/assorted.vue?definePage&vue'
import _definePage_default_13 from '/var/www/continental/src/pages/app/feedback/entry/collection/index.vue?definePage&vue'
import _definePage_default_14 from '/var/www/continental/src/pages/app/feedback/episode.vue?definePage&vue'
import _definePage_default_15 from '/var/www/continental/src/pages/app/feedback/feedback-history.vue?definePage&vue'
import _definePage_default_16 from '/var/www/continental/src/pages/app/feedback/feedback-panel.vue?definePage&vue'
import _definePage_default_17 from '/var/www/continental/src/pages/app/feedback/master/category.vue?definePage&vue'
import _definePage_default_18 from '/var/www/continental/src/pages/app/feedback/master/config.vue?definePage&vue'
import _definePage_default_19 from '/var/www/continental/src/pages/app/feedback/master/form-type.vue?definePage&vue'
import _definePage_default_20 from '/var/www/continental/src/pages/app/feedback/master/source.vue?definePage&vue'
import _definePage_default_21 from '/var/www/continental/src/pages/app/feedback/master/tag.vue?definePage&vue'
import _definePage_default_22 from '/var/www/continental/src/pages/app/feedback/master/team.vue?definePage&vue'
import _definePage_default_23 from '/var/www/continental/src/pages/app/feedback/not-authorized.vue?definePage&vue'
import _definePage_default_24 from '/var/www/continental/src/pages/app/feedback/questionnaire/index.vue?definePage&vue'
import _definePage_default_25 from '/var/www/continental/src/pages/app/feedback/questionnaire/group.vue?definePage&vue'
import _definePage_default_26 from '/var/www/continental/src/pages/app/feedback/questionnaire/question.vue?definePage&vue'
import _definePage_default_27 from '/var/www/continental/src/pages/app/feedback/second-page.vue?definePage&vue'
import _definePage_default_28 from '/var/www/continental/src/pages/app/feedback/template/email.vue?definePage&vue'
import _definePage_default_29 from '/var/www/continental/src/pages/app/feedback/template/sms.vue?definePage&vue'
import _definePage_default_30 from '/var/www/continental/src/pages/app/feedback/template/whatsapp.vue?definePage&vue'
import _definePage_default_31 from '/var/www/continental/src/pages/app/feedback/ticket-management/index.vue?definePage&vue'
import _definePage_default_32 from '/var/www/continental/src/pages/app/feedback/ticket-management/board.vue?definePage&vue'
import _definePage_default_33 from '/var/www/continental/src/pages/app/feedback/ticket-management/compliment.vue?definePage&vue'
import _definePage_default_34 from '/var/www/continental/src/pages/app/feedback/utility/qrcode.vue?definePage&vue'
import _definePage_default_35 from '/var/www/continental/src/pages/app/global/escalation/index.vue?definePage&vue'
import _definePage_default_36 from '/var/www/continental/src/pages/app/global/escalation/create-update.vue?definePage&vue'
import _definePage_default_37 from '/var/www/continental/src/pages/app/global/master/department.vue?definePage&vue'
import _definePage_default_38 from '/var/www/continental/src/pages/app/global/master/location.vue?definePage&vue'
import _definePage_default_39 from '/var/www/continental/src/pages/app/global/master/location-type.vue?definePage&vue'
import _definePage_default_40 from '/var/www/continental/src/pages/app/global/master/unit.vue?definePage&vue'
import _definePage_default_41 from '/var/www/continental/src/pages/app/global/users/index.vue?definePage&vue'
import _definePage_default_42 from '/var/www/continental/src/pages/app/incident/audit/index.vue?definePage&vue'
import _definePage_default_43 from '/var/www/continental/src/pages/app/incident/capa/index.vue?definePage&vue'
import _definePage_default_44 from '/var/www/continental/src/pages/app/incident/capa-calender.vue?definePage&vue'
import _definePage_default_45 from '/var/www/continental/src/pages/app/incident/dashboard/index.vue?definePage&vue'
import _definePage_default_46 from '/var/www/continental/src/pages/app/incident/incident/index.vue?definePage&vue'
import _definePage_default_47 from '/var/www/continental/src/pages/app/incident/incident/entry/index.vue?definePage&vue'
import _definePage_default_48 from '/var/www/continental/src/pages/app/incident/incident/entry/view.vue?definePage&vue'
import _definePage_default_49 from '/var/www/continental/src/pages/app/incident/master/category.vue?definePage&vue'
import _definePage_default_50 from '/var/www/continental/src/pages/app/incident/master/contributory-factor.vue?definePage&vue'
import _definePage_default_51 from '/var/www/continental/src/pages/app/incident/master/fishbone-category.vue?definePage&vue'
import _definePage_default_52 from '/var/www/continental/src/pages/app/incident/master/incident-category.vue?definePage&vue'
import _definePage_default_53 from '/var/www/continental/src/pages/app/incident/master/investigation-group.vue?definePage&vue'
import _definePage_default_54 from '/var/www/continental/src/pages/app/incident/master/question/index.vue?definePage&vue'
import _definePage_default_55 from '/var/www/continental/src/pages/app/incident/master/question/entry.vue?definePage&vue'
import _definePage_default_56 from '/var/www/continental/src/pages/app/incident/master/tag.vue?definePage&vue'
import _definePage_default_57 from '/var/www/continental/src/pages/app/incident/master/type.vue?definePage&vue'
import _definePage_default_58 from '/var/www/continental/src/pages/app/incident/rca/index.vue?definePage&vue'
import _definePage_default_59 from '/var/www/continental/src/pages/app/incident/rca/view.vue?definePage&vue'
import _definePage_default_60 from '/var/www/continental/src/pages/app/login.vue?definePage&vue'
import _definePage_default_61 from '/var/www/continental/src/pages/app/not-authorized.vue?definePage&vue'
import _definePage_default_62 from '/var/www/continental/src/pages/app/panel.vue?definePage&vue'
import _definePage_default_63 from '/var/www/continental/src/pages/feedback-collect/index.vue?definePage&vue'

export const routes = [
  {
    path: '/app',
    /* internal name: 'app' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':error(.*)',
        name: 'app$error',
        component: () => import('/var/www/continental/src/pages/app/[...error].vue'),
        /* no children */
      },
  _definePage_default_0
  ),
      {
        path: 'feedback',
        /* internal name: 'app-feedback' */
        /* no component */
        children: [
          {
            path: 'auth',
            /* internal name: 'app-feedback-auth' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'role',
                name: 'app-feedback-auth-role',
                component: () => import('/var/www/continental/src/pages/app/feedback/auth/role.vue'),
                /* no children */
              },
  _definePage_default_2
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'capa-management',
            name: 'app-feedback-capa-management',
            component: () => import('/var/www/continental/src/pages/app/feedback/capa-management.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
  _mergeRouteRecord(
          {
            path: 'customer-communication',
            name: 'app-feedback-customer-communication',
            component: () => import('/var/www/continental/src/pages/app/feedback/customer-communication.vue'),
            /* no children */
          },
  _definePage_default_4
  ),
          {
            path: 'dashboard',
            /* internal name: 'app-feedback-dashboard' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-feedback-dashboard',
                component: () => import('/var/www/continental/src/pages/app/feedback/dashboard/index.vue'),
                /* no children */
              },
  _definePage_default_5
  ),
  _mergeRouteRecord(
              {
                path: 'complaint',
                name: 'app-feedback-dashboard-complaint',
                component: () => import('/var/www/continental/src/pages/app/feedback/dashboard/complaint.vue'),
                /* no children */
              },
  _definePage_default_6
  ),
              {
                path: 'department',
                /* internal name: 'app-feedback-dashboard-department' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'app-feedback-dashboard-department',
                    component: () => import('/var/www/continental/src/pages/app/feedback/dashboard/department/index.vue'),
                    /* no children */
                  },
  _definePage_default_7
  )
                ],
              },
  _mergeRouteRecord(
              {
                path: 'feedback',
                name: 'app-feedback-dashboard-feedback',
                component: () => import('/var/www/continental/src/pages/app/feedback/dashboard/feedback.vue'),
                /* no children */
              },
  _definePage_default_8
  ),
  _mergeRouteRecord(
              {
                path: 'rca',
                name: 'app-feedback-dashboard-rca',
                component: () => import('/var/www/continental/src/pages/app/feedback/dashboard/rca.vue'),
                /* no children */
              },
  _definePage_default_9
  ),
  _mergeRouteRecord(
              {
                path: 'ticket',
                name: 'app-feedback-dashboard-ticket',
                component: () => import('/var/www/continental/src/pages/app/feedback/dashboard/ticket.vue'),
                /* no children */
              },
  _definePage_default_10
  )
            ],
          },
          {
            path: 'entry',
            /* internal name: 'app-feedback-entry' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-feedback-entry',
                component: () => import('/var/www/continental/src/pages/app/feedback/entry/index.vue'),
                /* no children */
              },
  _definePage_default_11
  ),
  _mergeRouteRecord(
              {
                path: 'assorted',
                name: 'app-feedback-entry-assorted',
                component: () => import('/var/www/continental/src/pages/app/feedback/entry/assorted.vue'),
                /* no children */
              },
  _definePage_default_12
  ),
              {
                path: 'collection',
                /* internal name: 'app-feedback-entry-collection' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'app-feedback-entry-collection',
                    component: () => import('/var/www/continental/src/pages/app/feedback/entry/collection/index.vue'),
                    /* no children */
                  },
  _definePage_default_13
  )
                ],
              }
            ],
          },
  _mergeRouteRecord(
          {
            path: 'episode',
            name: 'app-feedback-episode',
            component: () => import('/var/www/continental/src/pages/app/feedback/episode.vue'),
            /* no children */
          },
  _definePage_default_14
  ),
  _mergeRouteRecord(
          {
            path: 'feedback-history',
            name: 'app-feedback-feedback-history',
            component: () => import('/var/www/continental/src/pages/app/feedback/feedback-history.vue'),
            /* no children */
          },
  _definePage_default_15
  ),
  _mergeRouteRecord(
          {
            path: 'feedback-panel',
            name: 'app-feedback-feedback-panel',
            component: () => import('/var/www/continental/src/pages/app/feedback/feedback-panel.vue'),
            /* no children */
          },
  _definePage_default_16
  ),
          {
            path: 'master',
            /* internal name: 'app-feedback-master' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'category',
                name: 'app-feedback-master-category',
                component: () => import('/var/www/continental/src/pages/app/feedback/master/category.vue'),
                /* no children */
              },
  _definePage_default_17
  ),
  _mergeRouteRecord(
              {
                path: 'config',
                name: 'app-feedback-master-config',
                component: () => import('/var/www/continental/src/pages/app/feedback/master/config.vue'),
                /* no children */
              },
  _definePage_default_18
  ),
  _mergeRouteRecord(
              {
                path: 'form-type',
                name: 'app-feedback-master-form-type',
                component: () => import('/var/www/continental/src/pages/app/feedback/master/form-type.vue'),
                /* no children */
              },
  _definePage_default_19
  ),
  _mergeRouteRecord(
              {
                path: 'source',
                name: 'app-feedback-master-source',
                component: () => import('/var/www/continental/src/pages/app/feedback/master/source.vue'),
                /* no children */
              },
  _definePage_default_20
  ),
  _mergeRouteRecord(
              {
                path: 'tag',
                name: 'app-feedback-master-tag',
                component: () => import('/var/www/continental/src/pages/app/feedback/master/tag.vue'),
                /* no children */
              },
  _definePage_default_21
  ),
  _mergeRouteRecord(
              {
                path: 'team',
                name: 'app-feedback-master-team',
                component: () => import('/var/www/continental/src/pages/app/feedback/master/team.vue'),
                /* no children */
              },
  _definePage_default_22
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'not-authorized',
            name: 'app-feedback-not-authorized',
            component: () => import('/var/www/continental/src/pages/app/feedback/not-authorized.vue'),
            /* no children */
          },
  _definePage_default_23
  ),
          {
            path: 'questionnaire',
            /* internal name: 'app-feedback-questionnaire' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-feedback-questionnaire',
                component: () => import('/var/www/continental/src/pages/app/feedback/questionnaire/index.vue'),
                /* no children */
              },
  _definePage_default_24
  ),
  _mergeRouteRecord(
              {
                path: 'group',
                name: 'app-feedback-questionnaire-group',
                component: () => import('/var/www/continental/src/pages/app/feedback/questionnaire/group.vue'),
                /* no children */
              },
  _definePage_default_25
  ),
  _mergeRouteRecord(
              {
                path: 'question',
                name: 'app-feedback-questionnaire-question',
                component: () => import('/var/www/continental/src/pages/app/feedback/questionnaire/question.vue'),
                /* no children */
              },
  _definePage_default_26
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'second-page',
            name: 'app-feedback-second-page',
            component: () => import('/var/www/continental/src/pages/app/feedback/second-page.vue'),
            /* no children */
          },
  _definePage_default_27
  ),
          {
            path: 'template',
            /* internal name: 'app-feedback-template' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'email',
                name: 'app-feedback-template-email',
                component: () => import('/var/www/continental/src/pages/app/feedback/template/email.vue'),
                /* no children */
              },
  _definePage_default_28
  ),
  _mergeRouteRecord(
              {
                path: 'sms',
                name: 'app-feedback-template-sms',
                component: () => import('/var/www/continental/src/pages/app/feedback/template/sms.vue'),
                /* no children */
              },
  _definePage_default_29
  ),
  _mergeRouteRecord(
              {
                path: 'whatsapp',
                name: 'app-feedback-template-whatsapp',
                component: () => import('/var/www/continental/src/pages/app/feedback/template/whatsapp.vue'),
                /* no children */
              },
  _definePage_default_30
  )
            ],
          },
          {
            path: 'ticket-management',
            /* internal name: 'app-feedback-ticket-management' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-feedback-ticket-management',
                component: () => import('/var/www/continental/src/pages/app/feedback/ticket-management/index.vue'),
                /* no children */
              },
  _definePage_default_31
  ),
  _mergeRouteRecord(
              {
                path: 'board',
                name: 'app-feedback-ticket-management-board',
                component: () => import('/var/www/continental/src/pages/app/feedback/ticket-management/board.vue'),
                /* no children */
              },
  _definePage_default_32
  ),
  _mergeRouteRecord(
              {
                path: 'compliment',
                name: 'app-feedback-ticket-management-compliment',
                component: () => import('/var/www/continental/src/pages/app/feedback/ticket-management/compliment.vue'),
                /* no children */
              },
  _definePage_default_33
  )
            ],
          },
          {
            path: 'utility',
            /* internal name: 'app-feedback-utility' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'qrcode',
                name: 'app-feedback-utility-qrcode',
                component: () => import('/var/www/continental/src/pages/app/feedback/utility/qrcode.vue'),
                /* no children */
              },
  _definePage_default_34
  )
            ],
          }
        ],
      },
      {
        path: 'global',
        /* internal name: 'app-global' */
        /* no component */
        children: [
          {
            path: 'escalation',
            /* internal name: 'app-global-escalation' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-global-escalation',
                component: () => import('/var/www/continental/src/pages/app/global/escalation/index.vue'),
                /* no children */
              },
  _definePage_default_35
  ),
  _mergeRouteRecord(
              {
                path: 'create-update',
                name: 'app-global-escalation-create-update',
                component: () => import('/var/www/continental/src/pages/app/global/escalation/create-update.vue'),
                /* no children */
              },
  _definePage_default_36
  )
            ],
          },
          {
            path: 'master',
            /* internal name: 'app-global-master' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'department',
                name: 'app-global-master-department',
                component: () => import('/var/www/continental/src/pages/app/global/master/department.vue'),
                /* no children */
              },
  _definePage_default_37
  ),
  _mergeRouteRecord(
              {
                path: 'location',
                name: 'app-global-master-location',
                component: () => import('/var/www/continental/src/pages/app/global/master/location.vue'),
                /* no children */
              },
  _definePage_default_38
  ),
  _mergeRouteRecord(
              {
                path: 'location-type',
                name: 'app-global-master-location-type',
                component: () => import('/var/www/continental/src/pages/app/global/master/location-type.vue'),
                /* no children */
              },
  _definePage_default_39
  ),
  _mergeRouteRecord(
              {
                path: 'unit',
                name: 'app-global-master-unit',
                component: () => import('/var/www/continental/src/pages/app/global/master/unit.vue'),
                /* no children */
              },
  _definePage_default_40
  )
            ],
          },
          {
            path: 'users',
            /* internal name: 'app-global-users' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-global-users',
                component: () => import('/var/www/continental/src/pages/app/global/users/index.vue'),
                /* no children */
              },
  _definePage_default_41
  ),
              {
                path: 'user',
                name: 'app-global-users-user',
                component: () => import('/var/www/continental/src/pages/app/global/users/user.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'incident',
        /* internal name: 'app-incident' */
        /* no component */
        children: [
          {
            path: 'audit',
            /* internal name: 'app-incident-audit' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-incident-audit',
                component: () => import('/var/www/continental/src/pages/app/incident/audit/index.vue'),
                /* no children */
              },
  _definePage_default_42
  )
            ],
          },
          {
            path: 'capa',
            /* internal name: 'app-incident-capa' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-incident-capa',
                component: () => import('/var/www/continental/src/pages/app/incident/capa/index.vue'),
                /* no children */
              },
  _definePage_default_43
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'capa-calender',
            name: 'app-incident-capa-calender',
            component: () => import('/var/www/continental/src/pages/app/incident/capa-calender.vue'),
            /* no children */
          },
  _definePage_default_44
  ),
          {
            path: 'dashboard',
            /* internal name: 'app-incident-dashboard' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-incident-dashboard',
                component: () => import('/var/www/continental/src/pages/app/incident/dashboard/index.vue'),
                /* no children */
              },
  _definePage_default_45
  )
            ],
          },
          {
            path: 'incident',
            /* internal name: 'app-incident-incident' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-incident-incident',
                component: () => import('/var/www/continental/src/pages/app/incident/incident/index.vue'),
                /* no children */
              },
  _definePage_default_46
  ),
              {
                path: 'entry',
                /* internal name: 'app-incident-incident-entry' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'app-incident-incident-entry',
                    component: () => import('/var/www/continental/src/pages/app/incident/incident/entry/index.vue'),
                    /* no children */
                  },
  _definePage_default_47
  ),
  _mergeRouteRecord(
                  {
                    path: 'view',
                    name: 'app-incident-incident-entry-view',
                    component: () => import('/var/www/continental/src/pages/app/incident/incident/entry/view.vue'),
                    /* no children */
                  },
  _definePage_default_48
  )
                ],
              }
            ],
          },
          {
            path: 'master',
            /* internal name: 'app-incident-master' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: 'category',
                name: 'app-incident-master-category',
                component: () => import('/var/www/continental/src/pages/app/incident/master/category.vue'),
                /* no children */
              },
  _definePage_default_49
  ),
  _mergeRouteRecord(
              {
                path: 'contributory-factor',
                name: 'app-incident-master-contributory-factor',
                component: () => import('/var/www/continental/src/pages/app/incident/master/contributory-factor.vue'),
                /* no children */
              },
  _definePage_default_50
  ),
  _mergeRouteRecord(
              {
                path: 'fishbone-category',
                name: 'app-incident-master-fishbone-category',
                component: () => import('/var/www/continental/src/pages/app/incident/master/fishbone-category.vue'),
                /* no children */
              },
  _definePage_default_51
  ),
  _mergeRouteRecord(
              {
                path: 'incident-category',
                name: 'app-incident-master-incident-category',
                component: () => import('/var/www/continental/src/pages/app/incident/master/incident-category.vue'),
                /* no children */
              },
  _definePage_default_52
  ),
  _mergeRouteRecord(
              {
                path: 'investigation-group',
                name: 'app-incident-master-investigation-group',
                component: () => import('/var/www/continental/src/pages/app/incident/master/investigation-group.vue'),
                /* no children */
              },
  _definePage_default_53
  ),
              {
                path: 'question',
                /* internal name: 'app-incident-master-question' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'app-incident-master-question',
                    component: () => import('/var/www/continental/src/pages/app/incident/master/question/index.vue'),
                    /* no children */
                  },
  _definePage_default_54
  ),
  _mergeRouteRecord(
                  {
                    path: 'entry',
                    name: 'app-incident-master-question-entry',
                    component: () => import('/var/www/continental/src/pages/app/incident/master/question/entry.vue'),
                    /* no children */
                  },
  _definePage_default_55
  )
                ],
              },
  _mergeRouteRecord(
              {
                path: 'tag',
                name: 'app-incident-master-tag',
                component: () => import('/var/www/continental/src/pages/app/incident/master/tag.vue'),
                /* no children */
              },
  _definePage_default_56
  ),
  _mergeRouteRecord(
              {
                path: 'type',
                name: 'app-incident-master-type',
                component: () => import('/var/www/continental/src/pages/app/incident/master/type.vue'),
                /* no children */
              },
  _definePage_default_57
  )
            ],
          },
          {
            path: 'rca',
            /* internal name: 'app-incident-rca' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-incident-rca',
                component: () => import('/var/www/continental/src/pages/app/incident/rca/index.vue'),
                /* no children */
              },
  _definePage_default_58
  ),
              {
                path: 'investigation',
                /* internal name: 'app-incident-rca-investigation' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'app-incident-rca-investigation',
                    component: () => import('/var/www/continental/src/pages/app/incident/rca/investigation/index.vue'),
                    /* no children */
                  }
                ],
              },
  _mergeRouteRecord(
              {
                path: 'view',
                name: 'app-incident-rca-view',
                component: () => import('/var/www/continental/src/pages/app/incident/rca/view.vue'),
                /* no children */
              },
  _definePage_default_59
  )
            ],
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'app-login',
        component: () => import('/var/www/continental/src/pages/app/login.vue'),
        /* no children */
      },
  _definePage_default_60
  ),
  _mergeRouteRecord(
      {
        path: 'not-authorized',
        name: 'app-not-authorized',
        component: () => import('/var/www/continental/src/pages/app/not-authorized.vue'),
        /* no children */
      },
  _definePage_default_61
  ),
  _mergeRouteRecord(
      {
        path: 'panel',
        name: 'app-panel',
        component: () => import('/var/www/continental/src/pages/app/panel.vue'),
        /* no children */
      },
  _definePage_default_62
  )
    ],
  },
  {
    path: '/feedback-collect',
    /* internal name: 'feedback-collect' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'feedback-collect',
        component: () => import('/var/www/continental/src/pages/feedback-collect/index.vue'),
        /* no children */
      },
  _definePage_default_63
  )
    ],
  }
]
